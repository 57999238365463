<template>
  <footer>
    <div class="content">
      <img src="~@/assets/img/logo.svg" alt="logo agni" />

      <div class="grid">
        <div class="social">
          <span>The #1 Tool for WordPress Functional Testing</span>
          <div class="icons">
            <a href="https://www.linkedin.com/company/agnirocks"><img src="~@/assets/img/footer/linkedin.jpeg" alt="ln"/></a>
            <a href="https://twitter.com/Agni39205770"><img src="~@/assets/img/footer/tw.svg" alt="tw"/></a>
          </div>
        </div>

        <ul>
          <li><router-link :to="{ name: 'Faq'}">FAQ</router-link></li>
          <li><router-link :to="{ name: 'Terms'}">Terms</router-link></li>
        </ul>

        <ul>
          <li><router-link :to="{name: 'PrivacyPolicy'}">Privacy Policy</router-link></li>
        </ul>

        <the-button color="blue" text="Contact Us" @click="openForm()"></the-button>
      </div>

      <span class="copy">Copyright © {{date}}</span>
    </div>
  </footer>

  <app-modal
    v-if="store.state.isModalOpen"
  ></app-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import TheButton from '@/components/ui/native/AppButton.vue';
import AppModal from '@/components/ui/AppModal.vue';
import ContactForm from '@/components/ui/ContactForm.vue';

export default defineComponent({
  components: { TheButton, AppModal },
  setup() {
    const store = useStore();

    function openForm() {
      store.dispatch('toggleModal', ContactForm);
    }

    return { store, openForm };
  },

  data() {
    return {
      date: 0,
    };
  },

  mounted() {
    this.date = new Date().getFullYear();
  },
});
</script>

<style lang="scss" scoped>
footer {
  padding: 100px 0;
  background: white;

  .content {
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      column-gap: 100px;
      row-gap: 40px;
      margin-top: 30px;

      .social {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 30px;
        min-width: 246px;

        span {
          font-size: 14px;
          line-height: 28px;
        }

        .icons {
          display: grid;
          grid-template-columns: repeat(2, 44px);
          column-gap: 20px;

          img {
            width: 40px;
          }
        }
      }

      ul {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        row-gap: 20px;

        li {
          a {
            font-size: 20px;
            color: #5a5454;
          }
        }
      }

      button{
        max-width: fit-content;
        max-height: 70px;
        border-radius: 20px;
      }
    }

    span.copy {
      margin-top: 60px;
      font-size: 14px;
      color: #969ca2;
    }
  }
}
</style>
