import axios from 'axios';
import { FormData } from '@/types';
import notify from '@/helpers/notify';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const contactForm = () => {
  async function sendForm(data: FormData) {
    return axios.post('https://formspree.io/f/xyyljwlo', data)
      .then((response) => {
        if (response.data.ok) {
          notify.success('Message sended succesfully');
        }
        return true;
      })
      .catch((error) => {
        notify.error('Unexpected error, please try again');
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  return {
    sendForm,
  };
};

export default contactForm;
