
import { defineComponent, reactive, toRefs } from 'vue';
import TheButton from '@/components/ui/native/AppButton.vue';
import TheHamburgerButton from '@/components/ui/TheHamburgerButton.vue';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface Data {
  isMobileNavActive: boolean;
  ulNavElement: HTMLElement;
  publicPath: string;
}

export default defineComponent({
  components: { TheButton, TheHamburgerButton },
  setup() {
    const mainNav = reactive<Data>({
      isMobileNavActive: false,
      ulNavElement: document.createElement('div'),
      publicPath: process.env.BASE_URL,
    });

    const getLogoLink = () => (window.innerWidth >= 705
      ? `${mainNav.publicPath}static/logo.svg`
      : `${mainNav.publicPath}static/logo-white.svg`);

    const toggleStateOfMobileNav = () => {
      if (window.innerWidth >= 705) { return; }
      mainNav.isMobileNavActive = !mainNav.isMobileNavActive;
      if (mainNav.isMobileNavActive) {
        disableBodyScroll(mainNav.ulNavElement);
      } else {
        enableBodyScroll(mainNav.ulNavElement);
      }
    };

    return {
      toggleStateOfMobileNav,
      getLogoLink,
      ...toRefs(mainNav),
    };
  },
});
