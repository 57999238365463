
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      isHidden: false,
    };
  },
  methods: {
    close() {
      this.isHidden = true;
      localStorage.setItem('cookies', 'accepted');
    },
  },
  beforeMount() {
    if (localStorage.getItem('cookies') === 'accepted') {
      this.isHidden = true;
    }
  },
});
