<template>
  <div class="cookies" :class="{'hidden': isHidden}">
    <span>This website is using cookies to give you a better experience. By using this website you agree to storing and using the cookies.</span>
    <button @click="close()">Close</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      isHidden: false,
    };
  },
  methods: {
    close() {
      this.isHidden = true;
      localStorage.setItem('cookies', 'accepted');
    },
  },
  beforeMount() {
    if (localStorage.getItem('cookies') === 'accepted') {
      this.isHidden = true;
    }
  },
});
</script>

<style lang="scss">
  .cookies {
    position: fixed;
    bottom: 20px;
    width: 100%;
    max-width: 1000px;
    background-color: #807cf4;
    border-radius: 5px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    padding: 16px;

    &.hidden {
      display: none;
    }

    span {
      font-size: 16px;
      color: white;
    }

    button{
      background: #a7cf02 0% 0% no-repeat padding-box;
      color: white;
      padding: 8px 16px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
</style>
