<template>
  <main class="container">
    <router-view></router-view>
    <the-footer></the-footer>
    <app-cookies/>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import TheFooter from './components/layout/TheFooter.vue';
import AppCookies from './components/ui/AppCookies.vue';

const nf = new Notyf({
  duration: 3000,
});

export default defineComponent({
  name: 'App',
  components: { TheFooter, AppCookies },
  provide() {
    return {
      notify: nf,
    };
  },
});
</script>

<style lang="scss">
  @import "scss/main";
</style>
