
import { defineComponent, onMounted } from 'vue';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/dark.css';

export default defineComponent({
  props: {
    inputId: {
      type: String,
      required: false,
    },
    flatpickr: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    classList: {
      type: String,
      required: false,
    },
    modelValue: {},
    required: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    onMounted(() => {
      if (props.flatpickr) {
        flatpickr(`#${props.inputId}`, {});
      }
    });
  },
});
