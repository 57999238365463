<template>
  <section class="dark">
    <div class="content">
      <h3>
        Set up Agni in less than three minutes
      </h3>

      <ul class="custom">
        <li>Install the Agni plugin from Wordpress.org</li>
        <li>Activate the plugin.</li>
        <li>Go to the plugin settings page, verify the settings and submit.</li>
        <li>Wait for the confirmation email with registration token.</li>
        <li>
          Enter registration token on the plugin settings page and submit.
        </li>
      </ul>

      <a href="#">
        <the-button color="green" text="Install for free"></the-button>
      </a>
    </div>
  </section>
</template>

<script lang="ts">
/* eslint-disable max-len */
import { defineComponent } from 'vue';
import TheButton from '@/components/ui/native/AppButton.vue';

export default defineComponent({
  components: {
    TheButton,
  },
});
</script>

<style lang="scss" scoped>
section.dark {
  background: url("~@/assets/img/tablet.jpg") no-repeat center right -150px / 40%,
    transparent linear-gradient(132deg, #5828ff 0%, #807cf4 100%) 0% 0%
      no-repeat padding-box;

  @media (max-width: 942px) {
    background: transparent linear-gradient(132deg, #5828ff 0%, #807cf4 100%) 0%
      0% no-repeat padding-box;
  }

  ul {
    margin-left: 70px;

    li {
      color: white;
      padding: 20px 0;

      &::before {
        width: 47px;
        height: 47px;
        font-size: 17px;
        background: #a5a2f6;
        line-height: 47px;
        left: -70px;
      }
    }
  }

  button {
    margin-top: 30px;
  }
}
</style>
