<template>
  <div class="modal">
    <component :is="dynamicComponent"></component>
    <app-close-icon class="close-icon" @click="closeModal()" />
  </div>
  <div class="overlay"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import AppCloseIcon from '@/components/icons/IconClose.vue';

export default defineComponent({
  components: { AppCloseIcon },
  setup() {
    const store = useStore();
    const dynamicComponent = store.state.modalContent;

    function closeModal() {
      store.commit('toggleModal');
    }

    return { closeModal, dynamicComponent };
  },
});
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-height: 80%;
  z-index: 10;
  box-shadow: 0px 0px 36px 15px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  background: white;
  border-radius: 5px;

  img {
    width: 100%;
  }
}
.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 9;
}
.close-icon {
  position: absolute;
  top: -60px;
  right: -60px;
}
</style>
