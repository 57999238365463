<template>
  <section class="video-section">
    <div class="content">
      <div class="left">
        <h1>
          Your WordPress In Check At All Times
        </h1>
        <span>
          Agni offers you the tools to keep your WordPress site monitored 24/7, tested by real users, with advanced stats available—all free of charge.
        </span>
        <a href="#">
          <the-button color="blue" text="Download for Free" />
        </a>
      </div>
      <div class="right">
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/wWApS6QwPAM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TheButton from '@/components/ui/native/AppButton.vue';

export default defineComponent({
  components: { TheButton },
});
</script>

<style lang="scss" scoped>
    .video-section{
      .content {
        padding-top: 170px 0;
        display: grid;
        row-gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr) );

        .left{
          grid-column: span 4;

          @media (max-width:1128px) {
            grid-column: span 7;
          }

          span{
            margin: 26px 0 48px 0;
          }
        }
        .right{
          //max-width: 700px;
          display: block;
          position: relative;
          height: 100%;
          grid-column: span 7;

          iframe{
            min-height: 380px;
            width: 100%;
            box-shadow: 20px 20px 56px #A5A2F64A;
            background: #fff 0% 0% no-repeat padding-box;
            border-radius: 32px;
          }
        }
      }
    }
</style>
