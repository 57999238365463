<template>
  <section class="reasons">
    <div class="content">
      <h2>What does Agni do?</h2>

      <div class="boxes">
        <the-box type="column">
          <img src="~@/assets/img/home/head.svg" alt="head">
          <span class="title">WordPress Uptime Monitoring</span>
          <span class="text">Agni automatically checks your site for any issues. If it finds one, you're immediately notified of it.</span>
        </the-box>

        <the-box type="column">
          <img src="~@/assets/img/home/monitor.svg" alt="monitor">
          <span class="title">Testing Done by Real Users</span>
          <span class="text">Your site is tested by real users—reporting any bugs or issues they come across—helping you to keep your site bug-free and user-friendly.</span>
        </the-box>

        <the-box type="column">
          <img src="~@/assets/img/home/direction.svg" alt="direction">
          <span class="title">Alerts and Statistics</span>
          <span class="text">Access your dashboard for advanced insights on site usage and stay updated via customizable notifications at all times.</span>
        </the-box>

        <the-box type="column">
          <img src="~@/assets/img/home/dollar.svg" alt="dollar">
          <span class="title">Free</span>
          <span class="text">Agni is completely cost-free. No payments or credit card needed.</span>
        </the-box>
      </div>

    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TheBox from '@/components/ui/TheBox.vue';

export default defineComponent({
  components: {
    TheBox,
  },
});
</script>

<style lang="scss" scoped>
  .reasons {
    background-color: #F8F8F8;

    .boxes{
      display: grid;
      grid-auto-flow: row dense;
      grid-template-columns: repeat(auto-fit, minmax(347px, 1fr));
      gap: 60px;
      margin-top: 60px;

      @media (max-width:705px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
    }
  }
</style>
