<template>
  <button :class="color">{{ text }}</button>
</template>

<style lang="scss" scoped>
$secondaryButtonColor: #807cf4;
$primaryButtonColor: #a7cf02 0% 0% no-repeat padding-box;

button {
  padding: 24px 54px;
  border-radius: 36px;

  &:hover {
    cursor: pointer;
  }

  &.blue {
    color: white;
    background: $secondaryButtonColor;
  }
  &.green {
    color: white;
    background: $primaryButtonColor;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    color: String,
    text: String,
  },
});
</script>
