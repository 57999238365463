<template>
  <section>
    <div class="content">
      <nav>
        <router-link class="bold" :to="{ name: 'Home'}" @click="toggleStateOfMobileNav"><img :src="getLogoLink()" alt="agni logo" /></router-link>
        <ul
          class="inline"
          :class="{ active: isMobileNavActive }"
          ref="ulNavElement"
        >
          <li>
            <router-link class="bold" :to="{ name: 'Home'}" @click="toggleStateOfMobileNav">
              <img src="~@/assets/img/home/home.svg" alt="house icon" />
              agni.rocks
            </router-link>
          </li>
          <li><router-link class="bold" :to="{ name: 'Faq'}" @click="toggleStateOfMobileNav">faq</router-link></li>
          <li><router-link class="bold" :to="{ name: 'About'}" @click="toggleStateOfMobileNav">who we are!</router-link></li>
          <li>
            <a href="https://s3.eu-central-1.amazonaws.com/storage.agni.rocks/wordpress/latest.zip"><the-button color="green" text="Download"/></a>
          </li>
        </ul>
      </nav>

      <the-hamburger-button
        @hamburgerClicked="toggleStateOfMobileNav()"
        class="burger-icon"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import TheButton from '@/components/ui/native/AppButton.vue';
import TheHamburgerButton from '@/components/ui/TheHamburgerButton.vue';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface Data {
  isMobileNavActive: boolean;
  ulNavElement: HTMLElement;
  publicPath: string;
}

export default defineComponent({
  components: { TheButton, TheHamburgerButton },
  setup() {
    const mainNav = reactive<Data>({
      isMobileNavActive: false,
      ulNavElement: document.createElement('div'),
      publicPath: process.env.BASE_URL,
    });

    const getLogoLink = () => (window.innerWidth >= 705
      ? `${mainNav.publicPath}static/logo.svg`
      : `${mainNav.publicPath}static/logo-white.svg`);

    const toggleStateOfMobileNav = () => {
      if (window.innerWidth >= 705) { return; }
      mainNav.isMobileNavActive = !mainNav.isMobileNavActive;
      if (mainNav.isMobileNavActive) {
        disableBodyScroll(mainNav.ulNavElement);
      } else {
        enableBodyScroll(mainNav.ulNavElement);
      }
    };

    return {
      toggleStateOfMobileNav,
      getLogoLink,
      ...toRefs(mainNav),
    };
  },
});
</script>

<style lang="scss" scoped>
section {
  @media (max-width: 705px) {
    padding: 0;
  }
}
section {
  @media (min-width: 1500px) {
    .content ul{
      position: absolute;
      right: -200px;
    }
  }
  @media (min-width: 1890px) {
    .content ul{
      right: -350px;
    }
  }
  @media (min-width: 2150px) {
    .content ul{
      right: -450px;
    }
  }
  @media (min-width: 2400px) {
    .content ul{
      right: -600px;
    }
  }
  @media (min-width: 2800px) {
    .content ul{
      right: -800px;
    }
  }
}
nav {
  position: relative;
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 705px) {
    padding-top: 20px;
  }

  ul {
    @media (max-width: 705px) {
      position: absolute;
      height: 100vh;
      top: 0;
      right: -15px;
      background-color: #7f66fa;
      z-index: 1;
      width: 300px;
      box-shadow: -7px 0px 23px 0px rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      transform: translateX(320px);
      transition: all 0.2s;

      &.active {
        transform: translateX(0px);
      }
    }

    li {
      margin-left: 65px;
      font-size: 21px;

      @media (max-width: 705px) {
        margin-left: 15px;
        margin-top: 20px;

        &:first-of-type {
          display: none;
        }

        a button {
          background: transparent !important;
          padding: 0;
          font-family: "pm-b";
        }
      }

      a {
        color: white;

        img {
          margin-right: 15px;
          vertical-align: bottom;
        }
      }
    }
  }
}
.burger-icon {
  margin-top: 30px;
}
</style>
