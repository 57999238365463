
import { defineComponent, reactive, toRefs } from 'vue';
import AppInput from '@/components/ui/native/AppInput.vue';
import contactForm from '@/helpers/contactForm';
import { FormData } from '@/types';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    AppInput,
  },
  setup() {
    const data = reactive<FormData>({
      inputName: '',
      inputEmail: '',
      inputNumber: '',
      inputMessage: '',
      form: document.createElement('form'),
    });

    const cf = contactForm();
    const store = useStore();

    function submitForm() {
      // validation here
      cf.sendForm(data).then((result) => {
        if (result) {
          data.form.reset();
          store.commit('toggleModal');
        }
      });
    }

    return { ...toRefs(data), submitForm };
  },
});
