
/* eslint-disable max-len */
import { defineComponent } from 'vue';
import TheButton from '@/components/ui/native/AppButton.vue';

export default defineComponent({
  components: {
    TheButton,
  },
});
