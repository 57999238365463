<template>
  <header>
    <main-nav></main-nav>
    <video-section></video-section>
  </header>

  <reasons-section></reasons-section>
  <how-to-section></how-to-section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HowToSection from './sections/TheHowTo.vue';
import VideoSection from './sections/TheVideo.vue';
import ReasonsSection from './sections/TheReasons.vue';
import MainNav from '../../components/layout/TheMainNav.vue';

export default defineComponent({
  components: {
    HowToSection,
    MainNav,
    VideoSection,
    ReasonsSection,
  },
});
</script>

<style lang="scss">
main {
  background: url("~@/assets/img/head.svg") no-repeat top -20px right -60px;
  background-size: 1217px;

  @media (max-width: 705px) {
    background: url("~@/assets/img/head.svg") no-repeat top -250px right -60px;
    background-size: 1217px;
  }

  header {
    section {
      padding-top: 68px;
    }
  }
}
</style>
