<template>
  <form
    action="javascript:;"
    class="contact-form"
    @submit.prevent="submitForm()"
    ref="form"
  >
    <app-input
      type="text"
      input-id="name"
      name="name"
      v-model="inputName"
      :required="true"
    >
      <template v-slot:label>
        <span>Your Name:</span>
      </template>
    </app-input>

    <app-input
      type="email"
      input-id="email"
      name="email"
      v-model="inputEmail"
      :required="true"
    >
      <template v-slot:label>
        <span>Email:</span>
      </template>
    </app-input>

    <app-input
      type="tel"
      input-id="number"
      name="number"
      v-model="inputNumber"
      :required="true"
    >
      <template v-slot:label>
        <span>Phone number:</span>
      </template>
    </app-input>

    <label for="message" class="textarea">
      <span>Your Message:</span>
      <textarea
        name="message"
        id="message"
        rows="5"
        v-model="inputMessage"
        placeholder="Message"
        required
      ></textarea>
    </label>

    <div class="button-container">
      <button type="submit">Send</button>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import AppInput from '@/components/ui/native/AppInput.vue';
import contactForm from '@/helpers/contactForm';
import { FormData } from '@/types';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    AppInput,
  },
  setup() {
    const data = reactive<FormData>({
      inputName: '',
      inputEmail: '',
      inputNumber: '',
      inputMessage: '',
      form: document.createElement('form'),
    });

    const cf = contactForm();
    const store = useStore();

    function submitForm() {
      // validation here
      cf.sendForm(data).then((result) => {
        if (result) {
          data.form.reset();
          store.commit('toggleModal');
        }
      });
    }

    return { ...toRefs(data), submitForm };
  },
});
</script>

<style lang="scss">
form.contact-form {
  padding: 20px;
  width: 100%;
  max-width: 625px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  min-width: 250px;
  max-height: 75vh;

  @media (max-height: 740px) {
    overflow-y: scroll;
  }

  label.input,
  label.textarea {
    padding: 10px;
    width: 100%;
    max-width: 290px;
  }

  label.input div {
    width: 100%;
    flex: 1 1 300px;

    input {
      width: 100%;
      font-size: 16px;
    }
  }

  label.textarea {
    height: 100%;
    max-width: 100%;

    textarea {
      width: 100%;
      resize: none;
    }
  }

  .button-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;

    button {
      background: #5828ff;
      color: white;
      width: fit-content;
      padding: 20px;
      height: fit-content;
      border-radius: 8px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
</style>
