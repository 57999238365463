
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import TheButton from '@/components/ui/native/AppButton.vue';
import AppModal from '@/components/ui/AppModal.vue';
import ContactForm from '@/components/ui/ContactForm.vue';

export default defineComponent({
  components: { TheButton, AppModal },
  setup() {
    const store = useStore();

    function openForm() {
      store.dispatch('toggleModal', ContactForm);
    }

    return { store, openForm };
  },

  data() {
    return {
      date: 0,
    };
  },

  mounted() {
    this.date = new Date().getFullYear();
  },
});
