import { createStore } from 'vuex';
import { markRaw } from 'vue';
import ContactForm from '@/components/ui/ContactForm.vue';

export default createStore({
  state: {
    isModalOpen: false,
    modalContent: ContactForm,
    securityToken: '',
  },
  mutations: {
    toggleModal(state) {
      state.isModalOpen = !state.isModalOpen;
    },
    changeModalContent(state, template) {
      state.modalContent = template;
    },
    setSecurityToken(state, data) {
      state.securityToken = data;
    },
  },
  actions: {
    toggleModal(mutations, content) {
      const template = () => {
        if (!content.render) {
          return markRaw({
            template: content,
          });
        }

        return markRaw(content);
      };

      mutations.commit('changeModalContent', template());
      mutations.commit('toggleModal');
    },
  },
  getters: {
    getSecurityToken(state) {
      return state.securityToken;
    },
  },
  modules: {
  },
});
