
import { defineComponent, ref } from 'vue';

export default defineComponent({
  emits: ['hamburgerClicked'],
  setup() {
    const isActive = ref(false);

    const toggleStateOfButton = () => {
      isActive.value = !isActive.value;
    };

    return { toggleStateOfButton, isActive };
  },
});
