
import { defineComponent } from 'vue';
import HowToSection from './sections/TheHowTo.vue';
import VideoSection from './sections/TheVideo.vue';
import ReasonsSection from './sections/TheReasons.vue';
import MainNav from '../../components/layout/TheMainNav.vue';

export default defineComponent({
  components: {
    HowToSection,
    MainNav,
    VideoSection,
    ReasonsSection,
  },
});
