
import { defineComponent } from 'vue';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import TheFooter from './components/layout/TheFooter.vue';
import AppCookies from './components/ui/AppCookies.vue';

const nf = new Notyf({
  duration: 3000,
});

export default defineComponent({
  name: 'App',
  components: { TheFooter, AppCookies },
  provide() {
    return {
      notify: nf,
    };
  },
});
