<template>
  <div class="box" :class="type">
    <slot></slot>
  </div>
</template>

<style lang="scss">
@import "@/scss/variables.scss";

.box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 40px 40px #c5c3f926;
  border-radius: 20px;
  width: 100%;
  padding: 60px 0;
  display: grid;

  @media (max-width: 705px) {
    padding: 40px 20px;
  }

  &.row {
    grid-auto-flow: column;
    column-gap: 64px;
    align-content: center;
    align-items: center;
  }

  &.column {
    grid-template-columns: 1fr;
    align-content: center;
    align-items: center;
    justify-items: center;
    text-align: center;
    row-gap: 23px;

    * {
      max-width: 80%;

      @media (max-width: 705px) {
        max-width: 100%;
      }
    }

    span.title {
      color: $headingsColor;
      font-size: 44px;
      line-height: 49px;
      font-family: "pm-b";

      @media (max-width: 705px) {
        font-size: 35px;
        line-height: 38px;
      }
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    type: String,
  },
});
</script>
