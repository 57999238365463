<template>
  <label class="input">
    <slot name="label"></slot>
    <div>
      <input
        :type="type"
        :name="name"
        :id="inputId"
        :placeholder="placeholder"
        :class="classList"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :required="required"
      />
      <slot name="icon"></slot>
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/dark.css';

export default defineComponent({
  props: {
    inputId: {
      type: String,
      required: false,
    },
    flatpickr: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    classList: {
      type: String,
      required: false,
    },
    modelValue: {},
    required: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    onMounted(() => {
      if (props.flatpickr) {
        flatpickr(`#${props.inputId}`, {});
      }
    });
  },
});
</script>

<style lang="scss">
label.input {
  div {
    border-radius: 14px;
    background: rgba($color: #a3a3a3, $alpha: 0.08);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    input {
      background: transparent;
      border-radius: 0px;
      padding: 11px 17px 11px 17px;
      border: none;
      width: fit-content;
      font-size: 22px;
      color: #5828ff;
      font-family: "pm";
      min-height: 52px;
      cursor: pointer;

      &.has-icon {
        padding: 11px 40px 11px 17px;
        max-width: 150px;
      }

      &.date {
        max-width: 195px;
      }
    }

    svg {
      position: absolute;
      right: 15px;
      pointer-events: none;
    }
  }
}
</style>
