
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import AppCloseIcon from '@/components/icons/IconClose.vue';

export default defineComponent({
  components: { AppCloseIcon },
  setup() {
    const store = useStore();
    const dynamicComponent = store.state.modalContent;

    function closeModal() {
      store.commit('toggleModal');
    }

    return { closeModal, dynamicComponent };
  },
});
